import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'reactstrap'
import RecentPress from '../components/recentPress'
import Layout from '../components/layout'
import Button from '../components/button'

export default ({ data }) => {
  const post = data.markdownRemark
  const image = data.pressImages.edges.find(edge => {
    return edge.node.relativePath === `press/${post.frontmatter.date}.jpg`
  })
  const imageSizes = image.node.childImageSharp.sizes
  return (
    <Layout>
      <div className="press-post-page">
        <Container>
          <Row>
            <Col className="content">
              <Img className="content-image" alt="" sizes={imageSizes} />
              <h1>{post.frontmatter.title}</h1>
              <div className="article-info">
                <span className="date">{post.frontmatter.date}</span>
                <span className="source">{post.frontmatter.source}</span>
              </div>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
              <a href={post.frontmatter.sourceUrl}>View the original article</a>
              <Link to="/press">
                <Button styleClass="explore-button">
                  See All Calvano News
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col className="recent-press-col">
              <h2>Recent Press</h2>
              <RecentPress />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        dateText
        source
        sourceUrl
        type
      }
    }

    # source: https://medium.freecodecamp.org/how-i-made-my-portfolio-website-blazing-fast-with-gatsby-82ccddc2f671
    pressImages: allFile(
      sort: { order: ASC, fields: [absolutePath] }
      filter: { relativePath: { regex: "/press/.*.jpg/" } }
    ) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            sizes(maxWidth: 1000) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
