import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PressButton from './pressButton'
import Slider from 'react-slick'

export default props => (
  <StaticQuery
    query={graphql`
      query {
        press: allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: { fields: { slug: { regex: "/press/.*/" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                date(formatString: "YYYY-MM-DD")
                dateText
                source
              }
              fields {
                slug
              }
            }
          }
        }
        pressImages: allFile(
          sort: { order: DESC, fields: [absolutePath] }
          filter: { relativePath: { regex: "/press/.*.jpg/" } }
        ) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const sliderSettings = {
        dots: true,
        infinite: true,
        lazyLoad: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      }
      return (
        <div className="recent-press">
          <Slider {...sliderSettings}>
            {data.press.edges.map(({ node }, index) => {
              const image = data.pressImages.edges.find(edge => {
                return (
                  edge.node.relativePath ===
                  `press/${node.frontmatter.date}.jpg`
                )
              })
              const fluid = image.node.childImageSharp.fluid
              const { title, date, dateText, source } = node.frontmatter
              return (
                <PressButton
                  key={node.id}
                  link={node.fields.slug}
                  id={node.id}
                  title={title}
                  date={date}
                  dateText={dateText}
                  source={source}
                  fluid={fluid}
                />
              )
            })}
          </Slider>
        </div>
      )
    }}
  />
)
